import React from 'react'; 

import Author1 from "../../assets/images/author1.jpg";
import Author2 from "../../assets/images/author2.jpg";
import Author3 from "../../assets/images/author3.jpg";

const Feedback = () => {
    return (
        <div className="bigdata-feedback-area pt-80 pb-50">
            <div className="container">
                <div className="section-title">
                    <h2>Social Responsibility</h2>
                    <div className="bar"></div>
                    <p>we take our social responsibility seriously by actively engaging in community development initiatives, implementing sustainable practices & supporting causes that promote positive change. We believe in making a meaningful impact on society & contributing to the greater good.</p>
                </div>
                
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-feedback-box">
                            <div className="feedback-desc">
                                <p>We focus in minimizing our impact on the environment, reducing waste & greenhouse gas emissions, conserving resources & adopting eco-friendly techniques.</p>
                            </div>

                            <div className="client-info mx-auto ml-0 p-0">
                                {/* <img src={Author1} alt="image" /> */}
                                <h3 class="text-center">Environmental</h3>
                                {/* <span>Founder & CEO</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-feedback-box">
                            <div className="feedback-desc">
                                <p>We promote social justice, supporting education and health initiatives, and engaging in community activities, in order to contribute to the well-being of society.</p>
                            </div>

                            <div className="client-info mx-auto ml-0 p-0">
                                {/* <img src={Author2} alt="image" /> */}
                                <h3 class="text-center">Social</h3>
                                {/* <span>Developer</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-feedback-box">
                            <div className="feedback-desc">
                                <p>We aim to conduct business ethically and transparently, paying fair wages to employees, and investing in local communities to create job opportunities.</p>
                            </div>

                            <div className="client-info mx-auto ml-0 p-0"> 
                                {/* <img src={Author3} alt="image" /> */}
                                <h3 class="text-center">Economic</h3>
                                {/* <span>Designer</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback;  